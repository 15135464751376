import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/header/seo"
import { makeStyles } from "@material-ui/core/styles"
import { injectIntl } from "gatsby-plugin-intl"
import { Grid } from "@material-ui/core"
import ToolCard from "../components/tools/toolCard"
import { FavStateContext } from "../context/favoriteContext"
import PageIntro from "../components/fragment/pageIntro"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 20px 50px",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 50px 50px",
    },
  },
  categoryName: {},
}))

const FavoritePage = props => {
  const { data } = props
  const classes = useStyles()
  const favState = useContext(FavStateContext)

  return (
    <Layout>
      <SEO title="Home" />
      <Grid container className={classes.container}>
        <PageIntro file={data.file} data={data.markdownRemark} />
        <Grid container spacing={3} className={classes.toolContainer}>
          {data.allMarkdownRemark.edges
            .filter(({ node }) => favState.favorites.includes(node.fields.slug))
            .map(({ node }) => (
              <Grid key={node.id} item xs={12} sm={6} md={4}>
                <ToolCard data={node} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default injectIntl(FavoritePage)

export const query = graphql`
  query($language: String) {
    file(relativePath: { eq: "undraw_like_dislike.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(filter: { fields: { lang: { eq: $language } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            licence
            costs
            link
          }
          fields {
            slug
            lang
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { lang: { eq: $language }, slug: { eq: "favorites" } }
    ) {
      frontmatter {
        slug
        title
        subtitle
      }
      html
    }
  }
`
